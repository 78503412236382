@font-face {
  font-family: 'sofi-bd';
  src: url('assets/fonts/Open-Sans-Bold.eot'),
  url('assets/fonts/Open-Sans-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'sofi-rg';
  src: url('assets/fonts/Open-Sans-Regular.eot'),
  url('assets/fonts/Open-Sans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'sofi-sb';
  src: url('assets/fonts/Open-Sans-SemiBold.eot'),
  url('assets/fonts/Open-Sans-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'sofi-bo';
  src: url('assets/fonts/Square721 BT Bold.eot'),
  url('assets/fonts/Square721 BT Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'sofi';
  src: url('assets/fonts/Square721 BT Roman.eot'),
  url('assets/fonts/Square721 BT Roman.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto-rg';
  src: url('assets/fonts/roboto-regular-webfont.eot'),
  url('assets/fonts/roboto-regular-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto-bo';
  src: url('assets/fonts/roboto-bold-webfont.eot'),
  url('assets/fonts/roboto-bold-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto-sb';
  src: url('assets/fonts/roboto-medium-webfont.eot'),
  url('assets/fonts/roboto-medium-webfont.woff2') format('woff2');
}
