.TicketFilter {
  display: flex;
  justify-content: space-between;

  input[type=checkbox], input[type=radio] {
    display: none;

    &:checked + label > span {
      border-radius: 0;
      top: -3px;
      left: 5px;
      width: 10px;
      height: 17px;
      border-width: 0 2px 2px 0;
      transform: rotate(40deg);
      border-color: var(--primaryColor);
    }

    + label {
      position: relative;
      height: 18px;
      padding-left: 25px;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      font-size: 11px;
      cursor: pointer;

      + input + label {
        margin-left: 1.5em;
      }

      > span {
        border-radius: 100.0%;
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        border-width: 2px;
        transition: all 50ms ease-in-out 0ms;
        border-color: rgba(083, 087, 099, 0.25);
      }
    }
  }

}
