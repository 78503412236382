@mixin icon($name) {
  background-image: url($name);
  opacity: 0.42;
  display: inline-block;
  padding: 12px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 24px;
  transition: all ease-in-out 150ms;
}

.add {
  @include icon("../../assets/imgs/colored/baseline-add_circle-24px_colored.svg");
  filter: var(--filter-primary);
}

.add_black {
  @include icon("../../assets/imgs/add_black_24dp.svg");

}

.add_blue {
  @include icon("../../assets/imgs/colored/add_24dp_colored.svg");
  filter: var(--filter-primary);
  transition: all 0.5s ease-in-out;

  &:hover {
    &:not(.disabled) {
      opacity: 1;
    }
  }
}

.edit-icon {
  @include icon("../../assets/imgs/edit.svg");
  filter: var(--filter-primary);
}

.copy {
  @include icon("../../assets/imgs/copy.svg");
  transition: all 0.5s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.print {
  @include icon("../../assets/imgs/colored/print_colored.svg");
  filter: var(--filter-primary);
  transition: all 0.5s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.del {
  @include icon("../../assets/imgs/ic_delete_forever_red_24px.svg");
  transition: all 0.5s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.cart-nav {
  @include icon("../../assets/imgs/ic_shopping_cart_gray_24px.svg");
  opacity: 1;
  background-size: 18px;
  transition: none;
}

.cart-nav-inverse {
  @include icon("../../assets/imgs/ic_shopping_cart_white_24px.svg");
  opacity: 1;
  background-size: 18px;
  transition: none;
}

.cart-notification {
  @include icon("../../assets/imgs/ic_shopping_cart_gray_24px.svg");
  opacity: 1;
  background-size: 24px;
}

.checked {
  @include icon("../../assets/imgs/check.svg");
  opacity: 1;
  background-size: 1.5em;
}

.dms-notification {
  padding: 2px;
  @include icon("../../assets/imgs/ic_sync_black_24dp.svg");
  opacity: 1;
  background-size: 20px;
}

.dms-notification-error {
  padding: 2px;
  @include icon("../../assets/imgs/ic_sync_problem_black_24dp.svg");
  opacity: 1;
  background-size: 20px;
}

.error-notification {
  @include icon("../../assets/imgs/baseline-report_problem-24px_red.svg");
  opacity: 1;
  background-size: 24px;
}

.assignment {
  @include icon("../../assets/imgs/local_shipping.svg");
  opacity: 1;
  background-size: 18px;
  transition: none;
}

.assignment-inverse {
  @include icon("../../assets/imgs/local_shipping_inverse.svg");
  opacity: 1;
  background-size: 18px;
  transition: none;
}

.reshipment-icon {
  @include icon("../../assets/imgs/arrow_circle_left.svg");
  opacity: 1;
  background-size: 24px;
  transition: none;
}

.new-reshipment-icon {
  @include icon("../../assets/imgs/arrow_circle_left_red.svg");
  opacity: 1;
  background-size: 24px;
  transition: none;
}

.reshipment-icon-inverse {
  @include icon("../../assets/imgs/arrow_circle_left_inverse.svg");
  opacity: 1;
  background-size: 24px;
  transition: none;
}

.nav_proposal {
  @include icon("../../assets/imgs/proposal.svg");
  opacity: 1;
  background-size: 18px;
  transition: none;
}

.nav_proposal-inverse {
  @include icon("../../assets/imgs/proposal.svg");
  opacity: 1;
  background-size: 18px;
  transition: none;
  filter: invert(100%);
}

.vehicle-history-submit {
  @include icon("../../assets/imgs/history-white-24dp.svg");
  opacity: 1;
  border-radius: 0;
  height: 42px;
  width: 42px;
  font-size: 12px;

  &:hover {
    background-color: #535763;
  }
}

.search {
  @include icon("../../assets/imgs/ic_search_black_24px.svg");
  opacity: 1;
  background-size: 18px;
  transition: none;
}

.search-inverse {
  @include icon("../../assets/imgs/ic_search_white_24px.svg");
  opacity: 1;
  background-size: 18px;
  transition: none;
}

.cart-search {
  @include icon("../../assets/imgs/colored/shopping_cart_outline_colored.svg");
  opacity: 0.5;
  border-radius: 100.0%;
  padding: 15px;
  background-size: 18px;
  transition: all 0.5s ease-in-out;
  border-width: 2px;
  border-style: solid;
  border-color: var(--primaryColor);
  filter: var(--filter-primary);

  &:hover {
    opacity: 1;
  }
}

.cart-search-disabled {
  @include icon("../../assets/imgs/colored/shopping_cart_outline_colored.svg");
  opacity: 0.25;
  border-radius: 100.0%;
  padding: 15px;
  background-size: 18px;
  cursor: not-allowed;
  filter: var(--filter-primary);
}

.nav-watchList {
  @include icon("../../assets/imgs/playlist_add_check.svg");
  opacity: 1;
  transition: none;
}

.nav-watchList-inverse {
  @include icon("../../assets/imgs/playlist_add_check_inverse.svg");
  opacity: 1;
  transition: none;
}

.watchList {
  @include icon("../../assets/imgs/colored/playlist_add_colored.svg");
  filter: var(--filter-primary);
  opacity: 0.5;
  transition: all 0.5s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.watchList-disabled {
  @include icon("../../assets/imgs/colored/playlist_add_colored.svg");
  filter: var(--filter-primary);
  opacity: 0.25;
  border-radius: 100.0%;
  cursor: not-allowed;
}

.starblue {
  @include icon("../../assets/imgs/star_outline.svg");
  opacity: 0.5;
  transition: all 0.5s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.de {
  @include icon("../../assets/imgs/colored/gw_de_colored.svg");
  filter: var(--filter-primary);
}

.eu {
  @include icon("../../assets/imgs/colored/gw_eu_colored.svg");
  filter: var(--filter-primary);
}

.calender {
  @include icon("../../assets/imgs/ic_date_range_black_24px.svg");
}

.nav-calender {
  @include icon("../../assets/imgs/ic_date_range_black_24px.svg");
  opacity: 0.65;
  background-size: 18px;
}

.sum {
  @include icon("../../assets/imgs/gw_summe_black_24px.svg");
}

.doc {
  @include icon("../../assets/imgs/ic_insert_drive_file_black_24px.svg");
}

.help {
  @include icon("../../assets/imgs/ic_help_outline_24px.svg");
}

.download {
  @include icon("../../assets/imgs/ic_download_black_24px.svg");
}

.mail-nav {
  @include icon("../../assets/imgs/ic_mail_outline_black_24px.svg");
  opacity: 1;
  background-size: 18px;
}

.mail {
  @include icon("../../assets/imgs/ic_mail_outline_black_24px.svg");
}

.close {
  @include icon("../../assets/imgs/ic_close_black_24px.svg");
}

.close-icon-primary-colored {
  @include icon("../../assets/imgs/ic_close_black_24px.svg");
  filter: var(--filter-primary);
}

.close-addendum {
  @include icon("../../assets/imgs/ic_close_black_24px.svg");
  opacity: 1;
}

.arrow-up {
  @include icon("../../assets/imgs/ic_keyboard_arrow_up_black_24px.svg");
}

.arrow-top {
  @include icon("../../assets/imgs/colored/ic_arrow_top_colored.svg");
  filter: var(--filter-primary);
}

.arrow-left-black {
  @include icon("../../assets/imgs/ic_keyboard_arrow_left_black_24px.svg");
}

.arrow-right-black {
  @include icon("../../assets/imgs/ic_keyboard_arrow_right_black_24px.svg");
}

.arrow-skip-next {
  @include icon("../../assets/imgs/ic_skip_next_black_24px.svg");
}

.arrow-skip-pre {
  @include icon("../../assets/imgs/ic_skip_previous_black_24px.svg");
}

.gallery-prev {
  @include icon("../../assets/imgs/ic_keyboard_arrow_up_black_24px.svg");
  transform: rotate(270deg);
  height: 52px;
  width: 52px;
  background-size: contain;
  opacity: 1;
}

.gallery-next {
  @include icon("../../assets/imgs/ic_keyboard_arrow_up_black_24px.svg");
  transform: rotate(90deg);
  height: 52px;
  width: 52px;
  background-size: contain;
  opacity: 1;
}

.gallery-filler {
  @include icon("../../assets/imgs/ic_keyboard_arrow_up_black_24px.svg");
  display: inline-block;
  height: 52px;
  width: 52px;
  background-image: none;
}

.arrow-down {
  @include icon("../../assets/imgs/ic_keyboard_arrow_up_black_24px.svg");
  transform: scaleY(-1);
}

.filter-unchecked:hover {
  background-color: hsl(0, 0%, 90%);
}

.select-checked {
  @include icon("../../assets/imgs/ic_save_white_24px.svg");
  margin-left: -1px;
  opacity: 1;
  padding: 15px;
  background-size: 24px;
  background-color: var(--primaryColor);
}

.filter-checked {
  background-color: #535763;
  color: white;
}

.filter-box-checked {
  @include icon("../../assets/imgs/colored/ic_done_colored.svg");
  filter: var(--filter-primary);
  margin-right: 2px;
  transition-duration: 0s;
}

.delivery-selected {
  @include icon("../../assets/imgs/ic_done_white_24px.svg");
  background-color: black;
  background-size: 24px;
  padding: 12px;
  opacity: 1;
}

.delivery-unselected {
  @include icon("../../assets/imgs/ic_close_black_24px.svg");
  background-size: 20px;
  padding: 10px;
  opacity: 1;
  border: solid 2px black;
}

.logout {
  @include icon("../../assets/imgs/ic_exit_to_app_white_24px.svg");
  margin: 2px 6px 3px 60px;
  background-size: 18px;
  opacity: 1;
}

.user-profile {
  @include icon("../../assets/imgs/ic_person_white_24px.svg");
  margin: 2px 6px 3px 60px;
  background-size: 18px;
  opacity: 1;
}

.scroll-top {
  @include icon("../../assets/imgs/colored/ic_arrow_top_colored.svg");
  filter: var(--filter-primary);
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 16px;
  border: 2px solid var(--primaryColor);
  border-radius: 100.0%;
  display: block;
  z-index: 999;
  opacity: 1;
  transition: opacity 0.5s linear;
  background-color: transparent;

  &.hidden {
    opacity: 0;
    transition: opacity 0.5s linear;
  }
}

.accept {
  @include icon("../../assets/imgs/ic_thumb_up_black_24dp.svg");
  transition: all 0.5s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.reject {
  @include icon("../../assets/imgs/ic_thumb_down_black_24dp.svg");
  transition: all 0.5s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.info-outline {
  background-image: url("../../assets/imgs/info_outline_blue.svg");
  display: block;
  height: 24px;
  width: 24px;
}

.warning-yellow {
  @include icon("../../assets/imgs/warning_yellow.svg");
  opacity: 1;
}

