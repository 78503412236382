@import "./icon.scss";

button {
  border: 0;
  padding: 6px 15px;
  border-radius: 18px;
  transition: all ease-in-out 150ms;
  font-weight: 100;
  font-family: var(--fontFamily-normal);
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  background-color: var(--primaryColor);
  color: rgba(255, 255, 255, 1.00);
  text-decoration: none;
  cursor: pointer;

  &.flat {
    background-color: transparent;
    color: var(--primaryColor);
    font-weight: 100;
    font-family: var(--fontFamily-bold);
  }

  &.action {
    background-color: rgba(083, 087, 099, 1.00);
    color: rgba(255, 255, 255, 1.00);
  }

  &.big-link {
    background-color: transparent;
    color: var(--primaryColor);
    padding: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 42px;

    &.faq {
      background-image: url("../../assets/imgs/colored/ic_lightbulb_outline_colored.svg");
      filter: var(--filter-primary)
    }

    &.menu {
      background-image: url("../../assets/imgs/ic_menu_24px.svg");
      display: block;
      width: 60px;
      height: 60px;
      padding: 0;
      border-radius: 0;
      border-style: solid;
      border-width: 0 1px;
      border-color: #e0e0e0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 30px;

      &.open {
        background-image: url("../../assets/imgs/menu_open.svg");
      }
    }

    &.search {
      background-image: url("../../assets/imgs/colored/drive_eta-24px_colored.svg");
      filter: var(--filter-primary)
    }

    &.close {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }

}

button.filter-submit {
  border-radius: 0;
  background-color: black;
  opacity: 0.42;
  width: 100%;
  font-size: 12px;
}

button.gallery-indicator {
  width: 46px;
  height: 3px;
  display: inline-block;
  border-radius: 3px;
  background: var(--darker);
  opacity: 0.2;
  padding: 0;
  margin: 0 5px;
}

button.active-gallery-indicator {
  width: 46px;
  height: 3px;
  display: inline-block;
  border-radius: 3px;
  background: var(--primaryColor);
  opacity: 1;
  padding: 0;
  margin: 0 5px;
}

button.text-field-submit {
  background: var(--secondaryColor) url("../../assets/imgs/ic_search_white_24px.svg") no-repeat center;
  border-radius: 0;
  height: 42px;
  width: 42px;
  font-size: 12px;

  &:hover {
    background-color: black;
  }
}

button:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

button.secondary {
  color: var(--primaryColor);
  background-color: rgba(255, 255, 255, 1.00);
  font-weight: 100;
  font-family: var(--fontFamily-bold);
  padding: 0;
}

button.func {
  color: var(--primaryColor);
  background-color: rgba(255, 255, 255, 1.00);
  font-family: var(--fontFamily-normal);
  padding: 0;
  border: 1px solid var(--borderColor);
  border-radius: 2.4px;
  width: 26px;
  height: 26px;
  margin-right: 4px;
  float: left;

  &:hover:not([disabled]) {
    opacity: 1;
  }

  &.arrow-left-black {
    margin-right: 20px;
  }

  &.arrow-right-black {
    margin-left: 16px;
  }

  &.text {
    border: 0px;
    font-size: 12px;

    &:hover {
      color: #000;
    }
  }

  &.text:disabled {
    color: #000;
    opacity: 1;
  }
}

button.reset-search {
  margin-bottom: 10px;
}

button.filter-submit {
  text-transform: none;
}

.del-txt {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 100;
  font-family: var(--fontFamily-normal);
  text-transform: none;
  color: #e50000;
}

.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

  & > div {
    font-weight: 100;
    font-family: var(--fontFamily-normal);
    font-size: 14px;
  }
}

.tooltip {
  white-space: nowrap;
  font: normal 11px/18px var(--fontFamily-normal);
  padding: 4px 8px;
  line-height: 1.2;
  background-color: var(--backgroundColor);
  margin-right: 1px;
}
