$spinnerHeight: 50px;
$spinnerFragmentWidth: 30px;
$marginWidth: 8px;
$spinnerChildAmount: 5;
$startDelayGap: 0.15s;
$animationDuration: $spinnerChildAmount * $startDelayGap * 2;
$origin: 15px 15px;
$spinnerWidth: $spinnerFragmentWidth * 5 + $marginWidth * 5;

@function animationDelay($childPosition) {
  @return calc(0s - $animationDuration + ($childPosition - 1) * $startDelayGap);
}

.spinner-easter,
.spinner-default {
  $spinnerWidth: $spinnerFragmentWidth * 5 + $marginWidth * 5;
  $spinnerFragmentWidth: 8px;
  $marginWidth: 2px;

  &.spinner {
    width: $spinnerWidth;
    height: 20px;
    text-align: center;

    > div {
      background-color: var(--primaryColor, #000);
      height: 50%;
      width: $spinnerFragmentWidth;
      display: inline-block;
      margin-right: $marginWidth;

      -webkit-animation: stretchdelay-default $animationDuration infinite ease-in-out;
      animation: stretchdelay-default $animationDuration infinite ease-in-out;
    }

    > div:nth-child(2) {
      animation-delay: animationDelay(2);
    }

    > div:nth-child(3) {
      animation-delay: animationDelay(3);
    }

    > div:nth-child(4) {
      animation-delay: animationDelay(4);
    }

    > div:nth-child(5) {
      animation-delay: animationDelay(5);
    }
  }
}

.spinner-autumn {
  width: $spinnerWidth;
  height: $spinnerHeight;

  > div {
    height: 50%;
    width: $spinnerFragmentWidth;
    display: inline-block;
    animation: stretchdelay-easter $animationDuration infinite ease-in-out;
    margin-right: $marginWidth;
    transform-origin: $origin;
    background: url("../../assets/imgs/autumn/leaves.svg") no-repeat;
    background-size: contain;
  }

  > div:nth-child(2) {
    animation-delay: animationDelay(2);
  }

  > div:nth-child(3) {
    animation-delay: animationDelay(3);
  }

  > div:nth-child(4) {
    animation-delay: animationDelay(4);
  }

  > div:nth-child(5) {
    animation-delay: animationDelay(5);
  }
}

@-webkit-keyframes stretchdelay-default {
  0%, 40%, 100% {
    -webkit-transform: scale(0.4, 0.4);
  }
  20% {
    -webkit-transform: scale(1.0, 2.0);
  }
}

@keyframes stretchdelay-default {
  0%, 40%, 100% {
    transform: scale(0.4, 0.4);
    -webkit-transform: scale(0.4, 0.4);
  }
  20% {
    transform: scale(1.0, 2.0);
    -webkit-transform: scale(1.0, 2.0);
  }
}

@-webkit-keyframes stretchdelay-easter {
  0%, 40%, 100% {
    transform: scale(0.8, 0.8);
  }
  20% {
    transform: scale(2.0, 2.0);
  }
}

@keyframes stretchdelay-easter {
  0%, 40%, 100% {
    transform: scale(0.8, 0.8);
  }
  20% {
    transform: scale(2.0, 2.0);
  }
}
