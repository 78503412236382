@import "./typo.scss";

html {
  &.noscroll {
    overflow: hidden;
  }
}

#root {
  > .spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

body {
  margin: 0;
  overflow-y: auto;
  font-family: var(--fontFamily-normal);
  font-size: 14px;
  line-height: 1.2;
}

.cart-table > div {
  text-align: center;
}

a.btn {
  display: inline-block;
  border: 0;
  padding: 6px 15px;
  border-radius: 18px;
  transition: all ease-in-out 150ms;
  font-weight: 100;
  font-family: var(--fontFamily-normal);
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  background-color: var(--primaryColor);
  color: var(--inverseTextColor);
  text-decoration: none;
  cursor: pointer;
  margin: 1.2rem 0;
}
