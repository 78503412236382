nav.medialib + .content form.Questionnaire {
  background-color: var(--backgroundColor);
  padding: 2rem;

  h3 {
    line-height: 1.4;
  }

  * {
    white-space: pre-wrap;
  }

  .control {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: auto;
    }
  }

  h3 {
    margin: 1.2rem 0 0.7rem;
  }

  .Answer {
    display: flex;

    + .Answer {
      margin-top: 0.7rem;
    }

    span {
      margin-left: 1rem;
    }
  }

  #attendee {
    box-shadow: inset 1px 1px 0 0 var(--borderColor), inset -1px 0 0 0 var(--borderColor), inset 0 -10px 0px -8px var(--borderColor);
  }

  input[type="checkbox"],
  input[type="radio"] {
    display: none;
    appearance: none;
    width: 40px;
    height: 40px;
    margin: 1.2rem 0;

    &:checked + label:before {
      background-image: url("../../../assets/imgs/ic_done_white_24px.svg");
    }

    &[type="radio"] + label:before {
      border-radius: 50%;
    }

    + label {
      position: relative;
      width: auto;
      min-width: 6.00rem;
      height: 2.40rem;
      padding-left: 2.50rem;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: center;
      align-items: center;

      &:before {
        border-radius: 10.00%;
        position: absolute;
        top: 0.30rem;
        left: 0;
        height: 0;
        width: 0;
        padding: 0.90rem;
        display: block;
        content: "";
        box-shadow: inset 0 0 0 1px rgb(0 0 0 / 30%);
        background-color: var(--borderColorFocus);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80.0% auto;
      }
    }
  }
}
